import Base = require("Everlaw/Base");
import Bugsnag = require("Everlaw/Bugsnag");
import Checkbox = require("Everlaw/UI/Checkbox");
import { Compare as Cmp } from "core";
import { ColorTokens, EverColor } from "design-system";
import Dom = require("Everlaw/Dom");
import FocusContainerWidget = require("Everlaw/UI/FocusContainerWidget");
import Project = require("Everlaw/Project");
import Radio = require("Everlaw/UI/Radio");
import SingleSelect = require("Everlaw/UI/SingleSelect");
import TextBox = require("Everlaw/UI/TextBox");
import TimezonesCommon = require("Everlaw/TimezonesCommon");
import UI = require("Everlaw/UI");

import { TimezoneData, TimezoneWithOffset } from "Everlaw/TimezoneData";
import {
    isTimezoneO,
    TimezoneN,
    TimezoneNO,
    TimezoneO,
    unSupportedMomentTimezones,
} from "Everlaw/DateUtil";
import * as moment from "moment-timezone";

/**
 * See TimezoneSelect.SelectNO for the differences between the various TimezoneSelect classes.
 */
interface TimezoneSelect {
    getTimezone(): string | TimezoneWithOffset;
    getTimezoneId(): TimezoneNO;
    setDisabled(disabled: boolean): void;
}

/* TODO Refactor this to remove module namespace */
/* eslint-disable-next-line @typescript-eslint/no-namespace */
module TimezoneSelect {
    export interface SelectNParams extends UI.WidgetWithTextBoxParams {
        onSelect?: (value: Base.Primitive<TimezoneN>) => void;
        initialSelected?: Base.Primitive<TimezoneN>;
    }

    export function wrapTimezoneN(tz: TimezoneN, commonColor = EverColor.EVERBLUE_40) {
        const name = tz.replace(/_/g, " ");
        const x = new Base.Primitive<TimezoneN>(tz, name);
        setColor(x, tz, commonColor);
        return x;
    }

    /**
     * See TimezoneSelect.SelectNO for the differences between the various TimezoneSelect classes.
     */
    export class SelectN
        extends SingleSelect<Base.Primitive<TimezoneN>>
        implements TimezoneSelect, UI.WidgetWithTextBox
    {
        constructor(params: SelectNParams = {}) {
            super({
                elements: (
                    moment.tz
                        .names()
                        .filter((tz) => !unSupportedMomentTimezones.has(tz)) as TimezoneN[]
                ).map((tz) => wrapTimezoneN(tz)),
                initialSelected: params.initialSelected || SelectN.getDefault(),
                onSelect: params.onSelect || (() => {}),
                headers: false,
                popup: "after",
                textBoxAriaLabel: params.textBoxAriaLabel,
                textBoxLabelContent: params.textBoxLabelContent,
                textBoxLabelPosition: params.textBoxLabelPosition,
                selectOnSame: true,
                comparator: (x, y) => {
                    return timezoneSelectComparator(x.id, y.id, x.id, y.id);
                },
            });
        }
        private static getDefault(): Base.Primitive<TimezoneN> {
            if (Project.CURRENT && Project.CURRENT.timezoneId) {
                return wrapTimezoneN(Project.CURRENT.timezoneId as TimezoneN);
            } else {
                return wrapTimezoneN("UTC" as TimezoneN);
            }
        }
        getTimezone(): TimezoneN {
            const wrappedValue: Base.Primitive<string> = this.getValue();
            if (wrappedValue) {
                return wrappedValue.id as TimezoneN;
            } else {
                return null;
            }
        }
        getTimezoneId() {
            return this.getTimezone();
        }
        override setValue(val: Base.Primitive<TimezoneN> | string, silent?: boolean) {
            super.setValue(val, silent);
        }
    }

    /**
     * The HybridSelectN is a type of TimezoneN selector that uses a Radio selector to
     * present the user with a list of commonly selected timezones, while using a standard SelectN
     * widget to allow the user to select from the full range of TimezoneN options if necessary
     *
     * See TimezoneSelect.SelectNO for the differences between the various TimezoneSelect classes.
     *
     * @author kilian
     */
    export class HybridSelectN
        extends FocusContainerWidget
        implements TimezoneSelect, UI.WidgetWithTextBox
    {
        private radio: Radio<Base.Primitive<TimezoneN>>;
        private selectN: SelectN;
        private otherTimezone: Checkbox;
        constructor(
            node: HTMLElement,
            params: SelectNParams = {},
            radioStrings: string[],
            onChange?: () => void,
            private widthInPixels = 405,
        ) {
            super(node);
            const radioItems: Base.Primitive<TimezoneN>[] = (radioStrings as TimezoneN[]).map(
                (tz) => wrapTimezoneN(tz, ColorTokens.PRIMARY),
            );
            radioItems.forEach((item: Base.Primitive<TimezoneN>) => {
                const slashIndex = item.name.indexOf("/");
                if (slashIndex) {
                    item.name = item.name.substring(slashIndex + 1);
                }
            });
            if (radioItems.length > 0) {
                const buttonWidth = Math.floor(this.widthInPixels / radioItems.length);
                this.radio = new Radio({
                    elements: radioItems,
                    width: buttonWidth + "px",
                    class: "skinny",
                });
                this.radio.onChange = onChange;
                this.radio.select(radioItems[0]);
                this.registerDestroyable(this.radio);
                Dom.place(this.radio, this.node);

                this.otherTimezone = new Checkbox({
                    parent: Dom.create(
                        "div",
                        { class: "timezone-hybrid-select-checkbox" },
                        this.node,
                    ),
                    label: "Other time zone",
                    state: false,
                    onChange: () => {
                        const isSet = this.otherTimezone.isSet();
                        if (isSet) {
                            this.radio.clearSelection();
                        }
                        Dom.style(this.selectN, "paddingTop", isSet ? "8px" : "0px");
                        this.radio.setDisabled(isSet);
                        Dom.show(timezoneSelectNNode, isSet);
                        onChange();
                    },
                });
                this.registerDestroyable(this.otherTimezone);
            }

            const timezoneSelectNNode = Dom.create(
                "div",
                { class: "timezone-hybrid-select-dropdown" },
                this.node,
            );
            if (params.onSelect) {
                const oldOnSelect = params.onSelect;
                params.onSelect = (value) => {
                    oldOnSelect(value);
                    onChange();
                };
            } else {
                params.onSelect = onChange;
            }
            this.selectN = new SelectN(params);
            this.selectN.removeMultiple(radioItems);
            this.selectN.select(this.firstUnremovedElement(this.selectN.elements, radioItems));
            this.registerDestroyable(this.selectN);
            Dom.show(timezoneSelectNNode, this.useSingleSelect());
            Dom.place(this.selectN, timezoneSelectNNode);
        }

        private useSingleSelect() {
            return !this.otherTimezone || this.otherTimezone.isSet();
        }

        private firstUnremovedElement(
            elements: Base.Primitive<TimezoneN>[][],
            removed: Base.Primitive<TimezoneN>[],
        ) {
            const removedSet = new Set();
            removed.forEach((item) => removedSet.add(item.id));
            for (const row of elements) {
                for (const elm of row) {
                    if (!removedSet.has(elm.id)) {
                        return elm;
                    }
                }
            }
            return null;
        }

        getValue(): Base.Primitive<TimezoneN> {
            return this.useSingleSelect() ? this.selectN.getValue() : this.radio.getValue();
        }

        getTimezone(): TimezoneN {
            const wrappedValue: Base.Primitive<string> = this.getValue();
            if (wrappedValue) {
                return wrappedValue.id as TimezoneN;
            } else {
                return null;
            }
        }

        getTimezoneId() {
            return this.getTimezone();
        }

        setDisabled(disabled: boolean) {
            if (this.radio) {
                this.otherTimezone.setDisabled(disabled);
                this.radio.setDisabled(disabled || this.useSingleSelect());
            }
            this.selectN.setDisabled(disabled);
        }

        setTextBoxAriaLabel(ariaLabel: string) {
            this.selectN.setTextBoxAriaLabel(ariaLabel);
        }

        setTextBoxLabelContent(labelContent: Dom.Content) {
            this.selectN.setTextBoxLabelContent(labelContent);
        }

        setTextBoxLabelPosition(position: TextBox.LabelPosition) {
            this.selectN.setTextBoxLabelPosition(position);
        }
    }

    export interface SelectOParams extends UI.WidgetWithTextBoxParams {
        onSelect?: (value: Base.DataPrimitive<TimezoneWithOffset>) => void;
        initialSelected?: Base.DataPrimitive<TimezoneWithOffset>;
    }

    function wrapTimezoneWithOffset(tz: TimezoneWithOffset) {
        const tzn = tz.name as TimezoneN;
        const nameNoUnderscores = tz.name.replace(/_/g, " ");
        const displayName = `${nameNoUnderscores} (${tz.abbr})`;
        const x = new Base.DataPrimitive(tz, tz.id, displayName);
        setColor(x, tzn);
        return x;
    }

    /**
     * See TimezoneSelect.SelectNO for the differences between the various TimezoneSelect classes.
     */
    export class SelectO
        extends SingleSelect<Base.DataPrimitive<TimezoneWithOffset>>
        implements TimezoneSelect
    {
        constructor(params: SelectOParams = {}) {
            super({
                elements: TimezoneData.asFlatArray.map(wrapTimezoneWithOffset),
                initialSelected: params.initialSelected || SelectO.getDefault(),
                onSelect: params.onSelect || (() => {}),
                headers: false,
                popup: "after",
                selectOnSame: true,
                comparator: (x, y) => {
                    return timezoneSelectComparator(
                        x.data.id,
                        y.data.id,
                        x.data.name as TimezoneN,
                        y.data.name as TimezoneN,
                    );
                },
                textBoxAriaLabel: params.textBoxAriaLabel,
                textBoxLabelContent: params.textBoxLabelContent,
                textBoxLabelPosition: params.textBoxLabelPosition,
            });
        }
        static getDefault(): Base.DataPrimitive<TimezoneWithOffset> {
            let tz;
            if (Project.CURRENT) {
                const tzName = Project.CURRENT.timezoneId as TimezoneN;
                tz = TimezoneData.asGroupedDict[tzName][0];
            } else {
                tz = TimezoneData.asFlatDict["UTC|UTC"];
            }
            return wrapTimezoneWithOffset(tz);
        }
        getTimezone(): TimezoneWithOffset {
            const wrappedValue = this.getValue();
            if (wrappedValue) {
                return wrappedValue.data;
            } else {
                return null;
            }
        }
        getTimezoneId() {
            const tz = this.getTimezone();
            if (tz) {
                return tz.id as TimezoneO;
            } else {
                return null;
            }
        }
    }

    export interface SelectNOParams {
        showOFirst?: boolean;
        n?: SelectNParams;
        o?: SelectOParams;
    }

    /**
     * The differences between SelectN, SelectO, and SelectNO:
     *
     * A Time zone selector can either have (what I refer to as) offsets, or not.
     *
     * O = offsets
     * N = "no" offsets
     *
     * This is best explained by example:
     *
     * A SelectN deals with elements like "US/Pacific", "US/Eastern", ...
     * And a SelectO: "US/Pacific (PDT)", "US/Pacific (PST)", "US/Eastern (EDT)", ...
     *
     * SelectO is used when the distinction between daylight and non-daylight time is important;
     * e.g. when searching for a doc based on time (and time zone) but not date.
     *
     * A SelectNO is simply a combination of the two. Only one is visible at a time, but you can
     * switch which is visible. The getTimezone() method returns the selected element of the visible
     * Select.
     *
     * See also TimezoneNO, N, and O in DateUtil.ts.
     *
     * @author pandu
     */
    export class SelectNO implements TimezoneSelect {
        _node: HTMLElement;
        private nVisible: boolean;
        private n: SelectN;
        private o: SelectO;
        /**
         * keepPrev is used for keeping state when switching between the two underlying Selects. Since
         * there are two Selects, we need to manually update state when switching between the two so the
         * user sees what they expect. For example:
         * <p>
         * N -> O: ("narrow") e.g. when switching from "Time only" with TimezoneO "US/Pacific (PST)"
         * to "Date only," we know unambiguously that the corresponding TimezoneN is "US/Pacific".
         * <p>
         * O -> N: ("broaden") e.g. when switching from "Date only" with TimezoneN "US/Pacific" to
         * "Time only", choose one of TimezoneOs corresponding to US/Pacific. (PST or PDT). It is
         * ambiguous which you should select; right now we just pick arbitrarily. (Prompting the user
         * seems too "loud" for date search, which is where this is used.)
         * <p>
         * See also narrow(), broaden(), registerSelection()
         */
        private keepPrev = false;
        constructor(params: SelectNOParams = {}) {
            this.n = new SelectN(this.makeParamsN(params));
            this.o = new SelectO(params.o || {});

            this.showTimezoneSelectN(!params.showOFirst);

            this._node = Dom.div({}, this.n.getNode(), this.o.getNode());
        }
        private makeParamsN(params: SelectNOParams) {
            const nParams = params.n || {};
            const old = nParams.onSelect;
            nParams.onSelect = (elem) => {
                old && old(elem);
                this.keepPrev = false;
            };
            return nParams;
        }
        /**
         * If showN is true, show this.n. Else, show this.o.
         */
        showTimezoneSelectN(showN: boolean) {
            if (showN === this.nVisible) {
                return;
            }
            this.nVisible = showN;
            Dom.show(this.n, showN);
            Dom.show(this.o, !showN);

            if (showN) {
                this.narrow();
            } else {
                if (!this.keepPrev) {
                    // else do nothing; O's state is already correct!
                    this.broaden();
                }
            }
            this.keepPrev = showN;
        }
        /**
         * See keepPrev
         */
        narrow() {
            const tzName = this.o.getTimezone().name;
            const elem = wrapTimezoneN(tzName as TimezoneN);
            this.n.setValue(elem, true);
        }
        /**
         * See keepPrev
         */
        broaden() {
            const tzName = this.n.getTimezone();
            const unwrapped = TimezoneData.asGroupedDict[tzName][0];
            const elem = wrapTimezoneWithOffset(unwrapped);
            this.o.setValue(elem, true);
        }
        getTimezone() {
            return this.underlying().getTimezone();
        }
        getTimezoneId() {
            return this.underlying().getTimezoneId();
        }
        getNode() {
            return this._node;
        }
        /**
         * Get the underlying TimezoneSelect{N, O} that is actually currently visible.
         */
        private underlying() {
            if (this.nVisible) {
                return this.n;
            } else {
                return this.o;
            }
        }
        /**
         * The type of tz is slightly stricter (in an obvious way) than the method signature suggests.
         *
         * When the visible Select is the TimezoneSelectN, tz must be a TimezoneN, and when it's a
         * TimezoneSelectO, tz must be a TimezoneO.
         */
        setTimezone(tz: TimezoneNO) {
            const visibleSelect = this.underlying();
            if (isTimezoneO(tz)) {
                const x = TimezoneData.asFlatDict[tz];
                const val = wrapTimezoneWithOffset(x);
                if (visibleSelect instanceof SelectO) {
                    visibleSelect.setValue(val);
                } else {
                    Bugsnag.notify(Error("TimezoneSelectNO in wrong state"), {
                        metaData: {
                            nVisible: this.nVisible,
                        },
                        severity: "warning",
                    });
                }
            } else {
                const val = wrapTimezoneN(tz as TimezoneN);
                if (visibleSelect instanceof SelectN) {
                    visibleSelect.setValue(val);
                } else {
                    Bugsnag.notify(Error("TimezoneSelectNO in wrong state"), {
                        metaData: {
                            nVisible: this.nVisible,
                        },
                        severity: "warning",
                    });
                }
            }
        }
        setDisabled(disabled: boolean) {
            this.n.setDisabled(disabled);
            this.o.setDisabled(disabled);
        }
        openPopup() {
            this.underlying().openPopup();
        }
        focus() {
            this.underlying().focus();
        }
        blur() {
            this.underlying().blur();
        }
        destroy() {
            this.n.destroy();
            this.o.destroy();
        }
    }

    // Utility functions shared between TimezoneSelectN and TimezoneSelectO
    function setColor(
        x: { color?: string },
        tz: TimezoneN,
        commonColor = EverColor.EVERBLUE_40,
    ): void {
        if (isUtc(tz) || isProjectTz(tz) || isCommonTz(tz)) {
            x.color = commonColor;
        } else {
            x.color = EverColor.EVERBLUE_30;
        }
    }

    function isCommonTz(tz: TimezoneN) {
        return TimezonesCommon.forDateSearch.indexOf(tz) !== -1;
    }

    function isUtc(tz: TimezoneN) {
        return tz === "UTC";
    }

    function isProjectTz(tz: TimezoneN) {
        return !!Project.CURRENT && tz === Project.CURRENT.timezoneId;
    }

    export function timezoneSelectComparator(
        x: string,
        y: string,
        xN: TimezoneN,
        yN: TimezoneN,
    ): number {
        const alphabetical = Cmp.str(x, y);
        const commonOrProject =
            +(isCommonTz(yN) || isProjectTz(yN)) - +(isCommonTz(xN) || isProjectTz(xN));
        const utc = +isUtc(yN) - +isUtc(xN);
        return 100 * utc + 10 * commonOrProject + alphabetical;
    }
}

export = TimezoneSelect;
